<template>
  <div>
    <b-modal
      ref="my-modal"
      v-model="IsPopupActiveLocal"
      :title="$t('Add Language')"
      hide-footer
      @hidden="Reset"
      @close="Reset"
    >
      <div class="mb-2">
        {{ $t('Fill up your Language information') }}
      </div>
      <div class="con-exemple-prompt">
        <b-row>
          <b-col cols="12">
            <vx-input
              v-model="FillData.language"
              label="language"
              :errors="errors.language"
            />
          </b-col>
          <b-col cols="12">
            <vs-select
              v-model="FillData.status"
              :placeholder="$t('Level')"
              class="selectExample mb-1"
              style="width: 100%"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            >
              <vs-select-item
                v-for="(item,index) in employment_type"
                :key="index"
                :value="item.value"
                :text="item.text"
              />
            </vs-select>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="gradient-success"
              @click="Add"
            >
              {{ $t('Add') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>

/* eslint-disable */
export default {
  name: "AddLanguage",
  props: ['value'],

  data: () => ({
    FillData:{
      language:'',
      status:''
    },
    errors:[],
    employment_type:[
      {text: 'Beginner', value: 'beginner'},
      {text: 'PreIntermediate', value: 'pre_intermediate'},
      {text: 'Intermediate', value: 'intermediate'},
      {text: 'Upper Intermediate', value: 'upper_intermediate'},
      {text: 'Advance', value: 'advance'},
      {text: 'Native', value: 'native'},
    ],
  }),
  computed: {
    IsPopupActiveLocal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },

    },
  },
  methods: {
    Reset(){
      this.IsPopupActiveLocal = false
      this.FillData.language=''
      this.FillData.status=''
      this.errors=[]
    },
    Add() {

      this.$store.dispatch('employee/add_language',this.FillData).then(() => {
        this.Reset()
        this.$store.dispatch('frontend/GetUserProfile')
      }).catch(error => {
        if (error.response.data.errors) this.errors = error.response.data.errors
      })
    }
  },
}
</script>

<style scoped>

</style>
