<template>
  <div>
    <b-modal
      ref="my-modal"
      v-model="IsPopupActiveLocal"
      :title="$t('Add Occupations')"
      hide-footer
      @hidden="Reset"
      @close="Reset"
    >
      <div class="mb-2">
        {{ $t('Fill up your Occupations information') }}
      </div>
      <div class="con-exemple-prompt">
        <b-row>
          <b-col cols="12">
            <vx-input
              v-model="FillData.title"
              label="Occupations Title"
              :errors="errors.title"
            />
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="gradient-success"
              @click="Add"
            >
              {{ $t('Add') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>

/* eslint-disable */
export default {
  name: "AddOccupations",
  props: ['value'],

  data: () => ({
    FillData:{
      title:'',
    },
    errors:[],
  }),
  computed: {
    IsPopupActiveLocal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },

    },
  },
  methods: {
    Reset(){
      this.IsPopupActiveLocal = false
      this.FillData.title=''
      this.errors=[]
    },
    Add() {
      this.$store.dispatch('employee/add_occupation',this.FillData).then(() => {
        this.Reset()
        this.$store.dispatch('frontend/GetUserProfile')
      }).catch(error => {
        if (error.response.data.errors) this.errors = error.response.data.errors
      })
    }
  },
}
</script>

<style scoped>

</style>
