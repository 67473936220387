<template>
  <div class="text-center">
    <div v-if="file.id">
      <b-avatar
        class="my-1"
        size="70px"
        rounded
        variant="light-primary"
      >
        <fa-icon
          size="4"
          pack="far"
          icon="file-pdf"
        />
      </b-avatar>
      <br>
      <b-badge
        variant="primary"
        class="badge-glow my-1"
      >
        {{ file.original_name }}
      </b-badge>
      <br><b-badge
        variant="success"
        class="badge-glow mx-1"
      >
        {{ file.created_at }}
      </b-badge>
      <b-badge
        variant="success"
        class="badge-glow mx-1"
      >
        {{ file.fileSize }}
      </b-badge>
    </div>
    <br>
    <b-button
      v-if="!file.id"
      class="m-1"
      variant="success"
      @click="uploadCvButton"
    >
      <input
        ref="refCvEl"
        accept="application/pdf"
        type="file"
        name="file"
        class="d-none"
        @change="uploadCv($event)"
      >
      Upload Your CV
    </b-button>
    <br>
    <b-button
      v-if="file.id"
      class="m-1"
      variant="danger"
      @click="deleteCv"
    >
      Delete Your CV
    </b-button>
    <br>
    <b-button
      v-if="file.id"
      class="m-1"
      variant="warning"
      @click="downloadCv"
    >
      Download Your CV
    </b-button>
  </div>
</template>
<script>

export default {
  name: 'CvFileComponent',

  data: () => ({
    errors: [],
  }),
  computed: {
    file() {
      return this.$store.getters['upload/cv']
    },
  },
  methods: {
    uploadCvButton() {
      this.$refs.refCvEl.click()
    },
    uploadCv(event) {
      if (event.target.files[0]) {
        this.$store.dispatch('upload/uploadCv', event.target.files[0])
      }
    },
    deleteCv() {
      this.$store.dispatch('upload/resetCv')
    },
    downloadCv() {
      window.open(this.$fullImagePath(`${this.file.url}`))
    },

  },
}
</script>
