<template>
  <div>
    <b-modal
      ref="my-modal"
      v-model="IsPopupActiveLocal"
      :title="$t('Create Job')"
      hide-footer
      @hidden="Reset"
      @close="Reset"
    >
      <div class="mb-2">
        {{ $t('Fill up your Job information') }}
      </div>
      <div class="con-exemple-prompt">
        <b-row>
          <b-col cols="12">
            <vx-input
              v-model="FillData.job_title"
              :errors="errors.job_title"
              label="Job Title"
            />
          </b-col>
          <b-col cols="12">
            <vs-select
              v-model="FillData.status"
              :placeholder="$t('Status of the job')"
              class="selectExample mb-1"
              style="width: 100%"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            >
              <vs-select-item
                v-for="(item,index) in status"
                :key="index"
                :value="item.value"
                :text="item.text"
              />
            </vs-select>
            <vs-alert
              v-show="errors.status"
              v-if="errors.status"
              color="danger"
              active="true"
            >
              {{ $t(errors.status[0]) }}
            </vs-alert>
          </b-col>
          <b-col cols="12">
            <vs-select
              v-model="FillData.employment_type"
              :placeholder="$t('Employment Type')"
              class="selectExample mb-1"
              style="width: 100%"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            >
              <vs-select-item
                v-for="(item,index) in employment_type"
                :key="index"
                :value="item.value"
                :text="item.text"
              />
            </vs-select>
            <vs-alert
              v-show="errors.employment_type"
              v-if="errors.employment_type"
              color="danger"
              active="true"
            >
              {{ $t(errors.finish[0]) }}
            </vs-alert>
          </b-col>
          <b-col cols="12">
            <vs-select
              v-model="FillData.category_id"
              :placeholder="$t('Job Category')"
              class="selectExample mb-1"
              style="width: 100%"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            >
              <vs-select-item
                v-for="(item,index) in categories"
                :key="index"
                :value="item.id"
                :text="item.name"
              />
            </vs-select>
            <vs-alert
              v-show="errors.employment_type"
              v-if="errors.employment_type"
              color="danger"
              active="true"
            >
              {{ $t(errors.finish[0]) }}
            </vs-alert>
          </b-col>
          <b-col cols="12">
            <vs-textarea
              v-model="FillData.descriptions"
              label="Description"
            />
          </b-col>
          <b-col cols="12">
            <vs-textarea
              v-model="FillData.requirements"
              label="Requirements"
            />
          </b-col>
          <b-col cols="12">
            <vs-textarea
              v-model="FillData.job_address"
              label="Job Address"
            />
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="gradient-success"
              @click="Add"
            >
              {{ $t('Add') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>

import flatPickr from 'vue-flatpickr-component'
/* eslint-disable */
export default {
  name: "CreateJob",
  components: {
    flatPickr,
  },
  props: ['value'],

  data: () => ({
    FillData:{
      job_title:'',
      requirements:'',
      descriptions:'',
      job_address:'',
      employment_type:'',
      status:'',
      category_id:'',
    },
    errors:[],
    employment_type:[
      {text: 'Full Time', value: 'full_time'},
      {text: 'Part Time', value: 'part_time'},
      {text: 'Remote', value: 'remote'},
    ],
    status:[
      {text: 'Still Available', value: 'still_available'},
      {text: 'Unavailable', value: 'unavailable'},
    ],
  }),
  computed: {
    IsPopupActiveLocal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    categories(){
      return this.$store.getters['category/cats']
    }
  },
  methods: {
    Reset() {
      this.IsPopupActiveLocal = false
      this.FillData.requirements = ''
      this.FillData.job_title = ''
      this.FillData.descriptions = ''
      this.FillData.job_address = ''
      this.FillData.employment_type = ''
      this.FillData.status = ''
      this.FillData.category_id = ''
      this.errors=[]
    },
    Add() {
      this.$store.dispatch('company/create_job',this.FillData).then(() => {
        this.Reset()
        this.$store.dispatch('frontend/GetUserProfile')
      }).catch(error => {
        if (error.response.data.errors) this.errors = error.response.data.errors
      })
    },
  },
}
</script>

<style lang="scss">
@import './src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
