import { render, staticRenderFns } from "./EmployeeProfile.vue?vue&type=template&id=361d6d51&scoped=true&"
import script from "./EmployeeProfile.vue?vue&type=script&lang=js&"
export * from "./EmployeeProfile.vue?vue&type=script&lang=js&"
import style0 from "./EmployeeProfile.vue?vue&type=style&index=0&id=361d6d51&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "361d6d51",
  null
  
)

export default component.exports