<template>
  <div>
    <change-password-profile v-model="ChangePassword" />
    <create-job v-model="CreateJob" />
    <edit-job
      v-model="EditJobs"
      :job-id="jobId"
    />
    <b-modal
      v-model="DeleteJob"
      :title="$t('Confirm Delete')"
      :ok-title="$t('Delete')"
      ok-variant="danger"
      modal-class="modal-danger"
      cancel-variant="outline-secondary"
      :cancel-title="$t('Cancel')"
      centered
      @ok="acceptJobAlert"
    >
      <b-card-text>
        {{ $t('Are you sure you want to delete this Job?') }}
      </b-card-text>
    </b-modal>
    <b-row>
      <b-col>
        <b-card>
          <b-card-title>
            <b-row>
              <b-col cols="6">
                {{ $t('Profile') }}
              </b-col>
              <b-col
                cols="6"
                class="justify-content-end d-flex"
              >
                <b-button
                  variant="gradient-warning"
                  @click="ChangePassword=true"
                >
                  {{ $t('Change Password') }}
                </b-button>
              </b-col>
            </b-row>
          </b-card-title>
          <b-card-body>
            <b-row align-v="center">
              <b-col
                cols="12"
                lg="4"
                class="d-flex justify-content-center"
              >
                <vx-upload
                  v-model="UserData.avatar_id"
                  class="mt-2"
                />
              </b-col>
              <b-col
                cols="12"
                lg="8"
              >
                <b-row>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vx-input
                      v-model="UserData.first_name"
                      class="mt-1"
                      :label="$t('First Name')"
                      :errors="errors.first_name"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vx-input
                      v-model="UserData.last_name"
                      class="mt-1"
                      :label="$t('Last Name')"
                      :errors="errors.last_name"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vx-input
                      v-model="UserData.business_name"
                      class="mt-1"
                      :label="$t('Business Name')"
                      :errors="errors.business_name"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vx-input
                      v-model="UserData.contact_person"
                      class="mt-1"
                      :label="$t('Contact Person')"
                      :errors="errors.contact_person"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vx-input
                      v-model="UserData.phone"
                      class="mt-1"
                      type="number"
                      :label="$t('Phone')"
                      :errors="errors.phone"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <vs-textarea
                      v-model="UserData.address"
                      label="Address"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <vs-textarea
                      v-model="UserData.ex_address"
                      label="Second Address"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                class="mt-1"
                vs-w="12"
                align="end"
              >
                <vs-button
                  color="success"
                  class="mr-2 mb-2"
                  type="gradient"
                  @click="UpdateProfile"
                >
                  {{ $t('Confirm') }}
                </vs-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <div><h1>Our Jobs</h1></div>
      </b-col>
      <b-col
        cols="6"
        class="d-flex justify-content-end mb-2"
      >
        <b-button
          variant="gradient-primary"
          @click="CreateJob=true"
        >
          Create Job
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-for="(tr, indextr) in UserData.jobs"
        :key="indextr"
        class="d-flex"
        cols="12"
        lg="6"
      >
        <b-card class="w-100">
          <b-card-title>
            <b-row>
              <b-col cols="10">
                <h2>{{ tr.job_title }} at {{ UserData.business_name }}</h2>
              </b-col>
              <b-col
                class="d-flex justify-content-end"
                cols="2"
              >
                <a
                  class="mr-1"
                  @click="EditJob(tr.id)"
                >
                  <fa-icon
                    color="success"
                    pack="fas"
                    icon="edit"
                  />
                </a>
                <a @click="DeleteJobs(tr.id)">
                  <fa-icon
                    color="danger"
                    pack="fas"
                    icon="trash-alt"
                  />
                </a>
              </b-col>
            </b-row>
          </b-card-title>
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
              >
                <h3 class="section-title">
                  Description
                </h3>
                {{ tr.descriptions }}
              </b-col>
              <b-col
                cols="12"
                class="mt-2"
              >
                <h3 class="section-title">
                  Requirements
                </h3>
                {{ tr.requirements }}
              </b-col>
              <b-col
                class="mt-2"
                cols="12"
              >
                <h3 class="section-title">
                  Job Address
                </h3>
                {{ tr.job_address }}
              </b-col>
              <b-col
                cols="12"
                class="mt-2"
              >
                <h3 class="section-title">
                  Employment Type
                </h3>
                <vx-badge color="light-success">
                  {{ tr.employment_type }}
                </vx-badge>
              </b-col>
              <b-col
                class="mt-2"
                cols="12"
              >
                <h3 class="section-title">
                  Status Of The Job
                </h3>
                <vx-badge :color="tr.status==='unavailable'?'light-danger':'light-success'">
                  {{ tr.status }}
                </vx-badge>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>

              <b-col
                class="mt-2"
                cols="12"
              >
                <h3 class="section-title">
                  Category
                </h3>
                <vx-badge color="light-success">
                  {{ tr.category }}
                </vx-badge>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
import ChangePasswordProfile from "@/views/auth/components/ChangePasswordProfile";
import CreateJob from "@/views/auth/components/CreateJob";
import EditJob from "@/views/auth/components/EditJob";
export default {
    name: "CompanyProfile",
  components: {EditJob, CreateJob, ChangePasswordProfile},
  data: () => ({
      UserData: {},
      errors: [],
      ChangePassword: false,
      CreateJob: false,
      EditJobs: false,
      DeleteJob: false,
      jobId:''
    }),
  computed: {
    userData() {
      return this.$store.getters['frontend/userProfile']
    },
  },
  watch: {
    userData(val) {
      this.UserData = { ...val }
    },
  },
  methods: {
    UpdateProfile() {
      this.errors = []
      this.$store.dispatch('frontend/UpdateProfile', this.UserData).then(() => {
        this.$store.dispatch('auth/UpdateAuth')
      }).catch(error => {
        if (error.response.data.errors) this.errors = error.response.data.errors
      })
    },
    EditJob($val){
      this.jobId=$val
      this.$store.dispatch('company/job', this.jobId)
      this.EditJobs=true
    },
    DeleteJobs($val){
      this.jobId=$val
      this.DeleteJob=true
    },
    acceptJobAlert(){
      this.$store.dispatch('company/delete_job', this.jobId).then(() => {
        this.$store.dispatch('frontend/GetUserProfile')
      })
    },
  },
  created() {
    this.$store.dispatch('frontend/GetUserProfile')
  },
}
</script>

<style scoped>
.section-title{
  color: #B59341;
}
</style>
