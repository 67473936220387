<template>
  <b-container>
    <AdminProfile v-if="userType==='SuperAdmin'" />
    <CompanyProfile v-else-if="userType==='Company'" />
    <employee-profile v-else-if="userType==='Person'" />
  </b-container>
</template>
<script>
import AdminProfile from '@/views/auth/components/AdminProfile.vue'
import CompanyProfile from '@/views/auth/components/CompanyProfile.vue'
import EmployeeProfile from '@/views/auth/components/EmployeeProfile.vue'

export default {
  components: {
    EmployeeProfile,
    CompanyProfile,
    AdminProfile,
  },
  computed: {
    userType() {
      return this.$store.getters['frontend/userProfile'].user_type
    },
  },
  created() {
    this.$store.dispatch('frontend/GetUserProfile')
    this.$store.dispatch('category/GetHomeData')
  },
}
</script>
