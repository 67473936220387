<template>
  <div>
    <change-password-profile v-model="ChangePassword" />
    <b-row>
      <b-col>
        <b-card>
          <b-card-title>
            <b-row>
              <b-col cols="6">
                {{ $t('Profile') }}
              </b-col>
              <b-col
                cols="6"
                class="justify-content-end d-flex"
              >
                <b-button
                  variant="gradient-warning"
                  @click="ChangePassword=true"
                >
                  {{ $t('Change Password') }}
                </b-button>
              </b-col>
            </b-row>
          </b-card-title>
          <b-card-body>
            <b-row align-v="center">
              <b-col
                cols="12"
                lg="4"
                class="d-flex justify-content-center"
              >
                <vx-upload
                  v-model="UserData.avatar_id"
                  class="mt-2"
                />
              </b-col>
              <b-col
                cols="12"
                lg="8"
              >
                <b-row>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vx-input
                      v-model="UserData.first_name"
                      class="mt-1"
                      :label="$t('First Name')"
                      :errors="errors.first_name"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vx-input
                      v-model="UserData.last_name"
                      class="mt-1"
                      :label="$t('Last Name')"
                      :errors="errors.last_name"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vx-input
                      v-model="UserData.email"
                      class="mt-1"
                      :label="$t('Email')"
                      :errors="errors.email"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vx-input
                      v-model="UserData.phone"
                      class="mt-1"
                      :label="$t('Phone')"
                      :errors="errors.phone"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vs-textarea
                      v-model="UserData.address"
                      label="Address"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <vs-textarea
                      v-model="UserData.ex_address"
                      label="Second Address"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                class="mt-1"
                vs-w="12"
                align="end"
              >
                <vs-button
                  color="success"
                  class="mr-2 mb-2"
                  type="gradient"
                  @click="UpdateProfile()"
                >
                  {{ $t('Confirm') }}
                </vs-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
import ChangePasswordProfile from "@/views/auth/components/ChangePasswordProfile.vue";
export default {
    name: "AdminProfile",
  components: {ChangePasswordProfile},
  data() {
    return {
      UserData: {},
      ChangePassword: false,
      errors: [],
    }
  },
  computed: {
    userData() {
      return this.$store.getters['frontend/userProfile']
    },
  },
  watch: {
    userData(val) {
      this.UserData = { ...val }
    },
  },
  methods: {
    UpdateProfile() {
      this.errors = []
      this.$store.dispatch('frontend/UpdateProfile', this.UserData).then(() => {
        this.$store.dispatch('auth/UpdateAuth')
      }).catch(error => {
        if (error.response.data.errors) this.errors = error.response.data.errors
      })
    },
    created() {
      this.$store.dispatch('frontend/GetUserProfile')
    },
  },
}
</script>

<style scoped>

</style>
