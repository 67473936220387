<template>
  <div>
    <b-modal
      ref="my-modal"
      v-model="IsPopupActiveLocal"
      :title="$t('Add Experience')"
      hide-footer
      @hidden="Reset"
      @close="Reset"
    >
      <div class="mb-2">
        {{ $t('Fill up your Experience information') }}
      </div>
      <div class="con-exemple-prompt">
        <b-row>
          <b-col cols="12">
            <vx-input
              v-model="FillData.title"
              label="Experience Title"
              :errors="errors.title"
            />
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <h5 class="font-weight-bold">
                Start Date
              </h5>
              <flat-pickr
                v-model="FillData.from"
                class="form-control"
              />
            </b-form-group>
            <vs-alert
              v-show="errors.from"
              v-if="errors.from"
              color="danger"
              active="true"
            >
              {{ $t(errors.from[0]) }}
            </vs-alert>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <h5 class="font-weight-bold">
                End Date
              </h5>
              <flat-pickr
                v-model="FillData.to"
                class="form-control"
              />
            </b-form-group>
            <vs-alert
              v-show="errors.to"
              v-if="errors.to"
              color="danger"
              active="true"
            >
              {{ $t(errors.to[0]) }}
            </vs-alert>
          </b-col>
          <b-col cols="12">
            <vs-textarea
              v-model="FillData.description"
              label="Description"
            />
            <vs-alert
              v-show="errors.start"
              v-if="errors.start"
              color="danger"
              active="true"
            >
              {{ $t(FillData.description[0]) }}
            </vs-alert>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="gradient-success"
              @click="Add"
            >
              {{ $t('Add') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>

import flatPickr from 'vue-flatpickr-component'
/* eslint-disable */
export default {
  name: "AddExperience",
  components: {
    flatPickr,
  },
  props: ['value'],

  data: () => ({
    FillData:{
      title:'',
      from:'',
      to:'',
      description:'',
    },
    errors:[],
  }),
  computed: {
    IsPopupActiveLocal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },

    },
  },
  methods: {
    Reset(){
      this.IsPopupActiveLocal = false
      this.FillData.to=''
      this.FillData.description=''
      this.FillData.from=''
      this.FillData.title=''
      this.errors=[]
    },
    Add() {

      this.$store.dispatch('employee/add_experience',this.FillData).then(() => {
        this.Reset()
        this.$store.dispatch('frontend/GetUserProfile')
      }).catch(error => {
        if (error.response.data.errors) this.errors = error.response.data.errors
      })
    }
  },
}
</script>

<style lang="scss">
@import './src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
